export const ERRORS = "ERRORS";
export const LOADING = "LOADING";
export const REFRESHING = "REFRESHING";
export const FOOTER_LOADING = "FOOTER_LOADING";
export const SECONDARY_LOADING = "SECONDARY_LOADING";
export const GET_HOME_PAGE_DATA = "GET_HOME_PAGE_DATA";
export const GET_SEARCH_FILTERS = "GET_SEARCH_FILTERS";
export const SET_MODELS_EXTENTIONS = "SET_MODELS_EXTENTIONS";
export const GET_SEARCH_SUGGESTIONS = "GET_SEARCH_SUGGESTIONS";
export const SET_USER_DATA = "SET_USER_DATA";
export const GET_POST_SHARE_LINK = "GET_POST_SHARE_LINK";
export const ADD_POST_TO_FAVORITE = "ADD_POST_TO_FAVORITE";
export const DELETE_POST_FAVORITE = "DELETE_POST_FAVORITE";
export const ADD_COMMENT_TO_POST = "ADD_COMMENT_TO_POST";
export const ABUSE_POST_COMMENT = "ABUSE_POST_COMMENT";
export const ABUSE_POST = "ABUSE_POST";
export const POST_OFFLINE_ORDER = "POST_OFFLINE_ORDER";
export const RESET_POST_DATA = "RESET_POST_DATA";
export const REPOST = "REPOST";
export const POPUP_CAMPAIGN = "POPUP_CAMPAIGN";
export const GET_ADD_POST_PARAMS = "GET_ADD_POST_PARAMS";
export const GET_ADD_POST_MAKE_MODELS = "GET_ADD_POST_MAKE_MODELS";
export const GET_MARKETING_PAGE_DATA = "GET_MARKETING_PAGE_DATA";
export const GET_INITIAL_SEARCH_SUGGESTIONS = "GET_INITIAL_SEARCH_SUGGESTIONS";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const CREATE_ORDER = "CREATE_ORDER";
export const REFERRAL_ORDER = "REFERRAL_ORDER";
export const UPDATEFIELDS = "UPDATEFIELDS";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";
export const GET_HOME_PAGE_DATA_NEW = "GET_HOME_PAGE_DATA_NEW";
export const UPDATE_HOME_TAB = "UPDATE_HOME_TAB";
export const GET_HOME_PAGE_NEW = "GET_HOME_PAGE_NEW";
export const GET_HOME_PAGE_USED = "GET_HOME_PAGE_USED";
export const GET_FEEDBACK_LIST = "GET_FEEDBACK_LIST";
export const CONTACT_US_SUBMIT = "CONTACT_US_SUBMIT";
export const CONTACT_US_ERRORS = "CONTACT_US_ERRORS";
export const RESET_CHECKOUT_FIELDS = "RESET_CHECKOUT_FIELDS";
export const SET_TRAKING_ERRORS_MESSAGE = "SET_TRAKING_ERRORS_MESSAGE";
export const SHOW_TRACK_ORDER = "SHOW_TRACK_ORDER";
export const ORDER_CANCELED = "ORDER_CANCELED";
export const GET_REFERRAL_DATA = "GET_REFERRAL_DATA";
export const GET_REFERRAL_LIST = "GET_REFERRAL_LIST";
export const GET_CAR_INFO = "GET_CAR_INFO";
export const TRACKING_FORM = "TRACKING_FORM";
export const RESET_ORDER = "RESET_ORDER";
export const RESET_CAR_INFO = "RESET_CAR_INFO";
export const CREATE_FINANCE = "CREATE_FINANCE";
export const CREATE_FINANCE_ORDER_ERROR = "CREATE_FINANCE_ORDER_ERROR";
export const REJECT_FINANCE = "REJECT_FINANCE";
export const RESET_FINANCE_REJECTION_REASON = "RESET_FINANCE_REJECTION_REASON";
export const GET_FINANCE_ORDER_DATA = "GET_FINANCE_ORDER_DATA";
export const UPDATE_FINANCE_FIELDS = "UPDATE_FINANCE_FIELDS";
export const RESET_FINANCE_FIELDS = "RESET_FINANCE_FIELDS";
export const GET_FINANCE_OPTIONAL_REFUND = "GET_FINANCE_OPTIONAL_REFUND";
export const GET_OFFLINE_ORDER_DETAILS = "GET_OFFLINE_ORDER_DETAILS";
export const SURVEY_DATA = "SURVEY_DATA";
export const SURVEY_ORDER_DATA = "SURVEY_ORDER_DATA";
export const SET_SURVEY_CODE = "SET_SURVEY_CODE";
export const GET_REMAINING_ORDER_DETAILS = "GET_REMAINING_ORDER_DETAILS";
export const GET_PAYLINK_ORDER_DETAILS = "GET_PAYLINK_ORDER_DETAILS";
export const GET_FINANCE_REMAINING_ORDER_DETAILS =
  "GET_FINANCE_REMAINING_ORDER_DETAILS";
export const SADAD_INFORMATION = "SADAD_INFORMATION";
export const SADAD_ERROR = "SADAD_ERROR";
export const GET_NEW_SERVEY_DATA = "GET_NEW_SERVEY_DATA";
export const SET_SUBMIT_LOADING = "SET_SUBMIT_LOADING";
export const GET_NEW_POST_VIEW_DATA = "GET_NEW_POST_VIEW_DATA";
export const GET_POST_INSPECTION_DATA = "GET_POST_INSPECTION_DATA";
export const SHOW_FORM = "SHOW_FORM";
export const POST_LOADING = "POST_LOADING";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const SET_AUTH_DATA = "SET_AUTH_DATA";
export const UPDATE_SEARCH_POSTS = "UPDATE_SEARCH_POSTS";
export const UPDATE_POST_VIEW = "UPDATE_POST_VIEW";
export const GET_USER_FAVORITE = "GET_USER_FAVORITE";
export const AUTH_LOADING = "AUTH_LOADING";
export const UPDATE_SIMILAR_ITEMS = "UPDATE_SIMILAR_ITEMS";
export const GET_TRACKING_CHECKOUT_ORDERS = "GET_TRACKING_CHECKOUT_ORDERS";
export const TRACKING_FINANCE_ORDERS = "TRACKING_FINANCE_ORDERS";
export const TRACKING_FINANCE_DETAILS = "TRACKING_FINANCE_DETAILS";
export const RESET_TRACKING_CHECKOUT_ORDERS = "RESET_TRACKING_CHECKOUT_ORDERS";
export const GET_ALL_OFFERED_DOC = "GET_ALL_OFFERED_DOC";
export const GET_TRACKING_STEP_DETAILS = "GET_TRACKING_STEP_DETAILS";
export const RESET_TRACKING_STEP_DETAILS = "RESET_TRACKING_STEP_DETAILS";

export const LOGOUT_USER = "LOGOUT_USER";
export const GET_TRENDS_LIST = "GET_TRENDS_LIST";
export const GET_TRENDS_PAGINATION = "GET_TRENDS_PAGINATION";
export const GET_SINGLE_TREND = "GET_SINGLE_TREND";

/* search  */
export const GET_NEW_SEARCH_FILTERS = "GET_NEW_SEARCH_FILTERS";
export const GET_NEW_SEARCH_RESULTS = "GET_NEW_SEARCH_RESULTS";
export const SET_PRE_SELECTED_FILTERS = "SET_PRE_SELECTED_FILTERS";
// export const LOADING_FILTERS = "LOADING_FILTERS";
export const SEARCH_LOADING = "SEARCH_LOADING";
export const GET_SERVICE_CENTERS_DATA = "GET_SERVICE_CENTERS_DATA";
export const GET_SERVICE_CENTERS_RESULTS = "GET_SERVICE_CENTERS_RESULTS";
export const SEARCH_SUGGESTIONS = "SEARCH_SUGGESTIONS";
export const UNBXD_POSTS = "UNBXD_POSTS";
export const UNBXD_SERVICES_POSTS = "UNBXD_SERVICES_POSTS";
export const UNBXD_Filters = "UNBXD_Filters";
export const UNBXD_SELECTED_Filters = "UNBXD_SELECTED_Filters";
export const UNBXD_USPS = "UNBXD_USPS";
export const UNBXD_META_DATA = "UNBXD_META_DATA";
export const UNBXD_MARKETING_BANNER = "UNBXD_MARKETING_BANNER";
export const UNBXD_DEAL = "UNBXD_DEAL";
export const UNBXD_LOADING = "UNBXD_LOADING";
export const UNBXD_RECOMMENDATIONS = "UNBXD_RECOMMENDATIONS";
//TODO: remove this action with all dependencies
//@Suhaib
export const SET_PREV_URL = "SET_PREV_URL";
export const SIMILAR_ITEMS = "SIMILAR_ITEMS";
export const UNBXD_LOAD_MORE = "UNBXD_LOAD_MORE";
export const UNBXD_SPECS = "UNBXD_SPECS";
export const GET_EMKAN_DATA = "GET_EMKAN_DATA";
export const GET_CONTACT_US_DATA = "GET_CONTACT_US_DATA";
export const GET_FILTERS_SSR = "GET_FILTERS_SSR";
