var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
  return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
import fetcher from "@/common/axiosInstance";
import NotFound404Exception from "@/exceptions/NotFound404Exception";
import InternalServerError500Exception from "@/exceptions/InternalServerError500Exception";
var pendingRequests = {};
export function fetchSearchResults(req, axiosConfig) {
  return __awaiter(this, void 0, Promise, function () {
    var _this = this;
    return __generator(this, function (_a) {
      return [2 /*return*/, fetcher(req).get("/search/index", axiosConfig).then(function (res) {
        return __awaiter(_this, void 0, void 0, function () {
          var _a, _b, _c;
          return __generator(this, function (_d) {
            if (((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.code) !== 200) {
              throw new InternalServerError500Exception("api__doSearch", {
                url: "search/index",
                data: axiosConfig.params
              });
            }
            if (((_b = req === null || req === void 0 ? void 0 : req.query) === null || _b === void 0 ? void 0 : _b.page) && +req.query.page > res.data.data.total_pages) {
              throw new NotFound404Exception("api__doSearch_page_exceeds_total_pages");
            }
            return [2 /*return*/, ((_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.data) || {}];
          });
        });
      })];
    });
  });
}
export var api__doSearch = function api__doSearch(req_1) {
  var args_1 = [];
  for (var _i = 1; _i < arguments.length; _i++) {
    args_1[_i - 1] = arguments[_i];
  }
  return __awaiter(void 0, __spreadArray([req_1], __read(args_1), false), Promise, function (req, page, data, includes) {
    var requestData, cacheKey, axiosConfig, requestPromise;
    var _a;
    if (page === void 0) {
      page = 1;
    }
    if (data === void 0) {
      data = {};
    }
    if (includes === void 0) {
      includes = [];
    }
    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          if (((_a = req === null || req === void 0 ? void 0 : req.query) === null || _a === void 0 ? void 0 : _a.page) && (+req.query.page < 1 || isNaN(+req.query.page))) {
            throw new NotFound404Exception("api__doSearch_wrong_validation");
          }
          requestData = {
            includes: includes.join(","),
            search_data: JSON.stringify(data || {})
          };
          cacheKey = JSON.stringify(requestData);
          if (!(__IS_CSR__ && pendingRequests[cacheKey])) return [3 /*break*/, 2];
          return [4 /*yield*/, pendingRequests[cacheKey]];
        case 1:
          return [2 /*return*/, _b.sent()];
        case 2:
          axiosConfig = {
            params: requestData
          };
          requestPromise = fetchSearchResults(req, axiosConfig)["finally"](function () {
            if (__IS_CSR__) {
              delete pendingRequests[cacheKey];
            }
          });
          if (__IS_CSR__) {
            pendingRequests[cacheKey] = requestPromise;
          }
          return [4 /*yield*/, requestPromise];
        case 3:
          return [2 /*return*/, _b.sent()];
      }
    });
  });
};