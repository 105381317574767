var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var InternalServerError500Exception = /** @class */function (_super) {
  __extends(InternalServerError500Exception, _super);
  function InternalServerError500Exception(reason, extraData) {
    if (extraData === void 0) {
      extraData = {};
    }
    var _this = _super.call(this, "Internal Error") || this;
    _this.name = "InternalServerError500Exception";
    _this.type = 'api error';
    _this.statusCode = 500;
    _this.trace = reason;
    return _this;
  }
  return InternalServerError500Exception;
}(Error);
export default InternalServerError500Exception;