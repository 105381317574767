import { generateUniqueNumber } from "./GlobalJavascriptFunction";
import {
  onPushDataLayer,
  onPushDataLayerNew,
  onPushDataLayerObject,
} from "./events";
import { isRangeFilter } from "@/components/SearchComponent/UnbxdComponents/UnbxdAsideFilters/utils";
const pushFields = {
  color: {
    pushField: "post_exterior_color",
    searchParamName: "color",
    filterName: "colors",
  },
  interior_color: {
    pushField: "post_interior_color",
    searchParamName: "interior_color",
    filterName: "interior_colors",
  },
  milage_range: {
    pushField: "post_mileage_range",
    searchParamName: "milage_range",
  },
  is_new: {
    pushField: "post_condition",
    searchParamName: "is_new",
  },
  make_id: {
    pushField: "post_make",
    searchParamName: "make_id",
  },
  payment_type: {
    pushField: "post_purchase_method",
    searchParamName: "payment_type",
  },
  car_origin: {
    pushField: "post_origin",
    searchParamName: "car_origin",
  },
  fuel_type: {
    pushField: "post_fuel",
    searchParamName: "fuel_type",
    filterName: "fuel_types",
  },
  cabins_numbers: {
    pushField: "post_cabin",
    searchParamName: "cabins_numbers",
  },
  car_shape: {
    pushField: "post_car_shape",
    searchParamName: "car_shape",
  },
  city_id: {
    pushField: "post_city",
    searchParamName: "city_id",
  },
  engine_size: {
    pushField: "post_engine_size",
    searchParamName: "engine_size",
  },
  cylindars: {
    pushField: "post_cylindars",
    searchParamName: "cylindars",
  },
  drivetrain_type: {
    pushField: "post_drivetrain",
    searchParamName: "drivetrain_type",
  },
  cabin_numbers: {
    pushField: "post_cabin",
    searchParamName: "post_cabin",
    type: "custom",
  },
  post_type: {
    pushField: "post_user_type",
    searchParamName: "post_type",
  },
  year: {
    pushField: "post_year",
    searchParamName: "year_from",
    type: "range",
  },
  price: {
    pushField: "post_price",
    searchParamName: "price",
    type: "range",
  },
  is_online: {
    pushField: "search_section",
    searchParamName: "is_online",
  },
  model_id: {
    pushField: "post_model",
    searchParamName: "model_id",
  },
  extension_id: {
    pushField: "post_ext",
    searchParamName: "extension_id",
  },
  transmission_type: {
    pushField: "post_transmission",
    searchParamName: "post_transmission",
  },
  options_list: {
    pushField: "post_features",
    searchParamName: "post_features",
    type: "nested",
  },
};

export const cashCheckout1Event = (lead_type, g4_data) => {
  onPushDataLayerObject({
    event: "cash_checkout_1",
    ...g4_data,
    lead_type: lead_type,
  });
};
export const mojazReport = (lead_type, referral, g4_data) => {
  for (let key in g4_data) {
    if (key === "post_type" || key === "post_options") {
      delete g4_data[key];
    }
  }
  onPushDataLayerObject({
    screen_view_referral: referral,
    event: "mojaz_report",
    ...g4_data,
    // lead_type: lead_type, // deleted as requested
  });
};
export const cashCheckout3Event = (customer_mobile, g4_data) => {
  onPushDataLayerObject({
    event: "cash_checkout_3",
    customer_mobile: customer_mobile,
    // lead_type:  // deleted as mentioned in the ga4 excel sheet
    ...g4_data,
  });
};
export const onlineCashPurchaseEvent = (data, payment_option) => {
  onPushDataLayerObject({
    event: "online_cash_purchase",
    transaction_id: data?.id,
    payment_option: payment_option,
    customer_mobile: data?.customer?.mobile,
    warranty_package: data?.ga_data?.ecommerce?.purchase?.actionField
      ?.warrantyPackage
      ? data?.ga_data?.ecommerce?.purchase?.actionField?.warrantyPackage
      : null,
    roadservice_package: data?.roadService?.name_en
      ? data?.roadService?.name_en
      : null,
    total_amount: data?.total_price,
    referral_code: data?.referral_code ? data?.referral_code : null,
    ...data?.g4_data_layer,
  });
};
export const financeCheckout2Event = (customer_mobile, g4_data) => {
  const updated_g4_data = { ...g4_data };
  delete updated_g4_data.is_finance;
  onPushDataLayerObject({
    event: "finance_checkout_2",
    customer_mobile: customer_mobile,
    // lead_type:  // deleted as mentioned in the ga4 excel sheet
    ...updated_g4_data,
  });
};
export const onlineFinancePurchaseEvent = (
  transaction_id,
  payment_option,
  customer_mobile,
  application_bank,
  g4_data
) => {
  const updated_g4_data = { ...g4_data };
  delete updated_g4_data.is_finance;
  onPushDataLayerObject({
    event: "online_finance_purchase",
    transaction_id: transaction_id,
    payment_option: payment_option,
    application_bank: application_bank,
    customer_mobile: customer_mobile,
    ...updated_g4_data,
    // lead_type:  // deleted as mentioned in the ga4 excel sheet
  });
};



//[ post-view]finanace button events
export const finanaceButtonClickedEvents = (analytic, g4_data) => {
  // onPushDataLayer("finance.it", "Post Details", "widget", "finance.it.main");
  // onPushDataLayerObject({
  //   event: "finance_checkout_1",
  //   ...g4_data,
  // });
  if (analytic) {
    let layerData = analytic;
    if (layerData.isNew != 1) layerData = { ...layerData, metric3: 1 };
    else layerData = { ...layerData, metric4: 1 };
    onPushDataLayerObject({
      event: "addToCart",
      event_id: generateUniqueNumber(),
      ecommerce: {
        currencyCode: "SAR",
        add: {
          products: [layerData],
        },
      },
    });
  }
};


export const selectNewFiltersEvent = ({
  isOnline,
  searchReferral,
  filters,
  count,
  includeEmptyFilters = false,
  event = "search_desktop",
  search_query,
  isUnbxd = false,
}) => {
  let pushEventFields = includeEmptyFilters ? fillWithDefaultValues() : {};

  if (isUnbxd) {
    // return;

    const unbxdPushFields = getUnbxdPushFields(filters);
    pushEventFields = { ...pushEventFields, ...unbxdPushFields };
  } else {
    filters.forEach((item) => {
      const eventValue = pushFields[item.field];
      if (pushFields[item.field]) {
        if (item.field == "make_id") {
          const makesModelsExt = getMakesModelsExtNames(item.values);
          pushEventFields = { ...pushEventFields, ...makesModelsExt };
          return;
        }
        const otherFileds = extractOtherFields(
          eventValue.pushField,
          eventValue.type,
          item.values
        );
        pushEventFields = { ...pushEventFields, ...otherFileds };
      }
    });
  }

  onPushDataLayerObject({
    event,
    search_section: isOnline ? "Online" : "Syarah",
    search_type: searchReferral ? searchReferral : "search_screen",
    multi_select: Object.values(pushEventFields).some((eventField) =>
      eventField?.toString()?.includes(",")
    )
      ? "Yes"
      : "No",
    ...pushEventFields,
    search_pagination: 1,
    search_count_results: count,
    search_query: search_query,
  });
};

export const fillWithDefaultValues = () => {
  let fields;
  Object.keys(pushFields).forEach((field) => {
    if (field == "year" || field == "price") {
      fields = {
        ...fields,
        [`${pushFields[field].pushField}_from`]: "All",
        [`${pushFields[field].pushField}_to`]: "All",
      };
    } else {
      fields = { ...fields, [pushFields[field].pushField]: "All" };
    }
  });
  return fields;
};

export const getMakesModelsExtNames = (values) => {
  let fields = { post_make: [], post_model: [], post_ext: [] };
  values.forEach((make) => {
    fields.post_make.push(make.name_en);
    make.models.values.forEach((model) => {
      fields.post_model.push(model.name_en);
      model.extensions.values.forEach((extension) => {
        fields.post_ext.push(extension.name_en);
      });
    });
  });
  Object.keys(fields).forEach((item) => {
    fields[item].length
      ? (fields[item] = fields[item].join(","))
      : delete fields[item];
  });
  return fields;
};

export const extractOtherFields = (field, type, values) => {
  switch (type) {
    case "range":
      return {
        [`${field}_from`]: values[0].name,
        [`${field}_to`]: values[1].name,
      };
    case "custom":
      return {
        [field]: values
          .map((item) => {
            return item.id;
          })
          .join(","),
      };
    case "nested":
      const options = [];
      values.forEach((option) => {
        option.values.forEach((subOption) => {
          options.push(subOption.name_en);
        });
      });
      return {
        [field]: options.join(","),
      };
    default:
      return {
        [field]: values
          .map((item) => {
            return item.name_en;
          })
          .join(","),
      };
  }
};
export const oldImageViewEvent = (type) => {
  onPushDataLayer("image_view", "Post Details", "Other Action", type);
};
export const oldUserAccountEvent = (referral_location) => {
  onPushDataLayerNew(
    "user_account",
    "signup/login",
    referral_location,
    "Success"
  );
};
export const oldUserIdPropertyEvent = (user_id, logged_status) => {
  onPushDataLayerObject({
    event: "user_id_property",
    user_id: user_id,
    logged_status: logged_status,
    // event_id: generateUniqueNumber(),
  });
};

export const bookCarEvent = (g4_data) => {
  onPushDataLayerObject({
    event: "book_car",
    ...g4_data,
  });
};


function groupUnbxdFilters(filters) {
  const grouped = filters.reduce((acc, obj) => {
    const isRange = isRangeFilter(obj?.filterField);
    const filterField = mapUnbxdMultiFilters(obj?.filterField);

    if (isRange) return;

    if (!acc[filterField]) {
      acc[filterField] = [];
    }
    acc[filterField].push(obj);
    return acc;
  }, {});

  return grouped;
}

function mapUnbxdMultiFilters(filterName) {
  const changedNames = [
    "trim_id",
    "car_shape_id",
    "interior_color_id",
    "condition_id",
    "drivetrain_type_id",
    "cylinders",
    "car_origin_id",
    "color_id",
    "drivetrain_type_id",
    "fuel_type_id",
    "transmission_type_id",
    "transaction_type_id",
  ];

  if (!changedNames.includes(filterName)) return filterName;

  if (filterName === "trim_id") return "extension_id";
  if (filterName === "car_shape_id") return "car_shape";
  if (filterName === "interior_color_id") return "interior_color";
  if (filterName === "condition_id") return "is_new";
  if (filterName === "drivetrain_type_id") return "drivetrain_type";
  if (filterName === "cylinders") return "cylindars";
  if (filterName === "car_origin_id") return "car_origin";
  if (filterName === "color_id") return "color";
  if (filterName === "drivetrain_type_id") return "drivetrain_type";
  if (filterName === "fuel_type_id") return "fuel_type";
  if (filterName === "transmission_type_id") return "transmission_type";
  if (filterName === "transaction_type_id") return "payment_type";
}

function mapUnbxdRangeFilters(filters) {
  const newFilters = {};
  filters.forEach((filter) => {
    if (filter?.filterField === "sellingprice") {
      newFilters["post_price_from"] = filter.range_values.from;
      newFilters["post_price_to"] = filter.range_values.to;
    }
    if (filter?.filterField === "odometer") {
      newFilters[
        "post_mileage_range"
      ] = `From ${filter.range_values.from} KM to ${filter.range_values.to} KM`;
    }
    if (filter?.filterField === "year") {
      newFilters["post_year_from"] = filter.range_values.from;
      newFilters["post_year_to"] = filter.range_values.to;
    }
  });

  return newFilters;
}

const isOptionListFilters = (filterName) => {
  return filterName.includes("structured");
};

function getUnbxdPushFields(filters) {
  const fields = {};
  const noneRangeFilters = filters.filter(
    (filter) =>
      !isRangeFilter(filter?.filterField) &&
      !isOptionListFilters(filter?.filterField)
  );

  const rangeFilters = filters.filter((filter) =>
    isRangeFilter(filter?.filterField)
  );

  const optionListFilters = filters.filter((filter) =>
    isOptionListFilters(filter?.filterField)
  );

  const rangeFields = mapUnbxdRangeFilters(rangeFilters);

  const groupedFilters = groupUnbxdFilters(noneRangeFilters);

  const groupedOptionsList = optionListFilters.map((filter) => {
    return filter.name_en;
  });

  if (groupedOptionsList.length) {
    fields["post_features"] = groupedOptionsList.join(",");
  }

  Object.entries(groupedFilters).forEach(([key, value]) => {
    const eventValue = pushFields[key];
    fields[eventValue.pushField] = value.map((item) => item.name_en).join(",");
  });

  return { ...fields, ...rangeFields };
}