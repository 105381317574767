export const LOGIN_RESOURCE = "LOGIN_SOURCE";
export const FAV_RESOURCE = "FAV_RESOURCE";
export const COMMENT_RESOURCE = "COMMENT_RESOURCE";



export const CLASSIFIED = 0;
export const IS_ONLINE = 1;

export const CONDITION_USED = 0;
export const CONDITION_NEW = 1;
