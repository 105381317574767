var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// Post View
var TRACKING_POST_PARAMS = ['product_position', 'listing_type', 'page_number', 'is_rec', 'bigGallView'];
// Listing
var TRACKING_LISTING_PARAMS = ['referral_location', 'browse_variant', 'variant_value'];
export var getAndClearTrackingReferralParams = function getAndClearTrackingReferralParams(clearItems) {
  if (clearItems === void 0) {
    clearItems = true;
  }
  var trackingReferralParams = {};
  __spreadArray(__spreadArray([], __read(TRACKING_POST_PARAMS), false), __read(TRACKING_LISTING_PARAMS), false).forEach(function (key) {
    var value = localStorage.getItem(key);
    if (value) {
      trackingReferralParams[key] = value;
      if (clearItems) {
        localStorage.removeItem(key); // Clear the item from localStorage
      }
    }
  });
  return trackingReferralParams;
};
var useHashToLocalStoreEvent = function useHashToLocalStoreEvent() {
  var location = useLocation();
  var navigate = useNavigate();
  useEffect(function () {
    var handleHashChange = function handleHashChange() {
      if (location.hash) {
        var hash = location.hash.slice(1); // Remove the leading '#'
        var params_1 = new URLSearchParams(hash);
        __spreadArray(__spreadArray([], __read(TRACKING_POST_PARAMS), false), __read(TRACKING_LISTING_PARAMS), false).forEach(function (key) {
          var value = params_1.get(key);
          if (value) {
            localStorage.setItem(key, value);
          }
        });
        // Remove the hash from the URL without triggering a page reload
        // also include the query string if any
        navigate(location.pathname + location.search, {
          replace: true
        });
      }
    };
    handleHashChange(); // Call once on mount and when location changes
    // Cleanup function
    return function () {
      // No cleanup needed for this hook
    };
  }, [location, navigate]);
};
export default useHashToLocalStoreEvent;