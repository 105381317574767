var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var NotFound404Exception = /** @class */function (_super) {
  __extends(NotFound404Exception, _super);
  function NotFound404Exception(reason) {
    var _this = _super.call(this, "Not Found Page") || this;
    _this.name = "NotFound404Exception";
    _this.type = 'api error';
    _this.statusCode = 404;
    _this.trace = reason;
    return _this;
  }
  return NotFound404Exception;
}(Error);
export default NotFound404Exception;