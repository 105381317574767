var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function next() {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import cookieServer from "cookie";
import cookieClient from "react-cookies";
export var IsDom = !!(typeof window !== "undefined" && window.document && window.document.createElement);
export var Ctr = false;
export var SetCtr = function SetCtr(val) {
  Ctr = val;
};
export var getNumberWithOutPlus = function getNumberWithOutPlus(num) {
  return num === null || num === void 0 ? void 0 : num.replace("+966", "");
};
export var scrollToElement = function scrollToElement(element, scrollOffset) {
  var _a;
  var yOffset = scrollOffset;
  var el = element;
  var y = ((_a = el === null || el === void 0 ? void 0 : el.getBoundingClientRect()) === null || _a === void 0 ? void 0 : _a.top) + window.pageYOffset + yOffset;
  window.scrollTo({
    top: y
    //  behavior: "smooth"
  });
};
export function addSwipeEvents(objToSwipe, leftFunction, RightFunction) {
  if (objToSwipe) {
    var getTouches = function getTouches(evt) {
      return evt.touches ||
      // browser API
      evt.originalEvent.touches; // jQuery
    };
    var handleTouchStart = function handleTouchStart(evt) {
      var firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    };
    var handleTouchMove = function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }
      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;
      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;
      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          /* left swipe */
          document.querySelector("body").dir == "rtl" ? leftFunction() : RightFunction();
        } else {
          /* right swipe */
          //RightFunction();
          document.querySelector("body").dir == "rtl" ? RightFunction() : leftFunction();
        }
      } else {
        if (yDiff > 0) {
          /* up swipe */
        } else {
          /* down swipe */
        }
      }
      /* reset values */
      xDown = null;
      yDown = null;
    };
    objToSwipe.addEventListener("touchstart", handleTouchStart, {
      passive: true
    });
    objToSwipe.addEventListener("touchmove", handleTouchMove, {
      passive: true
    });
    var xDown = null;
    var yDown = null;
  }
}
export var AddSwipeUp = function AddSwipeUp(objToSwipe, functions) {
  if (objToSwipe) {
    var getTouches = function getTouches(evt) {
      return evt.touches ||
      // browser API
      evt.originalEvent.touches; // jQuery
    };
    var handleTouchStart = function handleTouchStart(evt) {
      var firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    };
    var handleTouchMove = function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }
      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;
      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;
      if (yDiff > 0) {
        /* up swipe */
        functions();
      }
      /* reset values */
      xDown = null;
      yDown = null;
    };
    objToSwipe.addEventListener("touchstart", handleTouchStart, false);
    objToSwipe.addEventListener("touchmove", handleTouchMove, false);
    var xDown = null;
    var yDown = null;
  }
};
export var generateUniqueNumber = function generateUniqueNumber(min, max) {
  if (min === void 0) {
    min = 100;
  }
  if (max === void 0) {
    max = 10000;
  }
  return Date.now() + "" + Math.floor(Math.random() * (max - min)) + min;
};
export var range = function range(min, max) {
  return Array.from({
    length: max - min + 1
  }, function (_, i) {
    return min + i;
  });
};
export function getDateThreeMonthsFromNow() {
  var currentDate = new Date();
  // Add three months to the current date
  currentDate.setMonth(currentDate.getMonth() + 3);
  // Format the date
  var year = currentDate.getFullYear();
  var month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  var day = currentDate.getDate().toString().padStart(2, "0");
  // Return the formatted date
  return "".concat(year, "-").concat(month, "-").concat(day);
}
export function normalizePrice(str) {
  if (typeof str !== "string") return null;
  // Replace commas with periods
  var formattedStr = str.replaceAll(",", "");
  // Parse the string as a floating-point number
  var number = parseFloat(formattedStr);
  return number;
}
export function parseJsonSafely(jsonString, defaultValue) {
  if (defaultValue === void 0) {
    defaultValue = null;
  }
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return defaultValue;
  }
}
export function capitalizeFirstLetter(string) {
  if (typeof string !== "string") return null;
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function generateYearsRangeList() {
  var endYear = new Date().getFullYear() + 1;
  var startYear = 2020;
  var yearsList = [];
  for (var x = startYear; x <= endYear; x++) {
    yearsList.push(x);
  }
  return yearsList;
}
export function extractPostId(text) {
  var e_1, _a;
  if (!text) return null;
  // Split the text by non-word characters
  var parts = decodeURIComponent(text).split(/[\W_]+/);
  var maxPostId = null;
  try {
    // Iterate through parts to find the maximum valid post ID
    for (var parts_1 = __values(parts), parts_1_1 = parts_1.next(); !parts_1_1.done; parts_1_1 = parts_1.next()) {
      var part = parts_1_1.value;
      // Convert part to a number
      var num = Number(part);
      // Check if it's a valid number and not a year in the range 2006-2024
      if (!isNaN(num) && (num < 2006 || num > 2024)) {
        if (maxPostId === null || num > maxPostId) {
          maxPostId = num;
        }
      }
    }
  } catch (e_1_1) {
    e_1 = {
      error: e_1_1
    };
  } finally {
    try {
      if (parts_1_1 && !parts_1_1.done && (_a = parts_1["return"])) _a.call(parts_1);
    } finally {
      if (e_1) throw e_1.error;
    }
  }
  // Return the maximum valid post ID found, or null if none found
  return maxPostId;
}
export var getUserAgent = function getUserAgent(req) {
  var _a;
  if (req) {
    return ((_a = req === null || req === void 0 ? void 0 : req.headers) === null || _a === void 0 ? void 0 : _a["user-agent"]) || "N.A-SSR-user-agent";
  }
  if (typeof window !== "undefined") {
    return window.navigator.userAgent;
  }
  return "N.A-user-agent";
};
export var getUniversalCookieValue = function getUniversalCookieValue(req, cookieName, defaultValue) {
  var _a;
  if (defaultValue === void 0) {
    defaultValue = null;
  }
  var userCookie = ((_a = req === null || req === void 0 ? void 0 : req.headers) === null || _a === void 0 ? void 0 : _a.cookie) ? cookieServer.parse(req.headers.cookie) : null;
  return (userCookie === null || userCookie === void 0 ? void 0 : userCookie[cookieName]) || cookieClient.load(cookieName) || defaultValue;
};
export var createTimestampAndNextNDays = function createTimestampAndNextNDays(timestamp) {
  var date = new Date(timestamp * 1000);
  date.setHours(date.getHours() + 3);
  var monthNames = {
    en: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    ar: ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"]
  };
  var dayNames = {
    en: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    ar: ["الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"]
  };
  var getDay = function getDay() {
    return date.getUTCDate();
  };
  var getMonth = function getMonth() {
    return date.getUTCMonth() + 1;
  };
  var getYear = function getYear() {
    return date.getUTCFullYear();
  };
  var getHours = function getHours() {
    return date.getUTCHours();
  };
  var getMinutes = function getMinutes() {
    return date.getUTCMinutes();
  };
  var getSeconds = function getSeconds() {
    return date.getUTCSeconds();
  };
  var getTime = function getTime() {
    var hours = date.getUTCHours().toString().padStart(2, '0');
    var minutes = date.getUTCMinutes().toString().padStart(2, '0');
    var seconds = date.getUTCSeconds().toString().padStart(2, '0');
    return "".concat(hours, ":").concat(minutes, ":").concat(seconds);
  };
  var getTimeForView = function getTimeForView(lang) {
    if (lang === void 0) {
      lang = 'en';
    }
    var pm = 'PM';
    var am = 'AM';
    if (lang === 'ar') {
      pm = 'م';
      am = 'ص';
    }
    var hours = date.getUTCHours();
    var minutes = date.getUTCMinutes();
    var ampm = hours >= 12 ? pm : am;
    var hours12 = (hours % 12 || 12).toString().padStart(2, '0');
    var minutesPadded = minutes.toString().padStart(2, '0');
    return "".concat(hours12, ":").concat(minutesPadded, " ").concat(ampm);
  };
  var getMonthName = function getMonthName(lang) {
    if (lang === void 0) {
      lang = 'en';
    }
    if (lang === 'ar') {
      return monthNames.ar[date.getUTCMonth()];
    }
    return monthNames.en[date.getUTCMonth()];
  };
  var getDayName = function getDayName(lang) {
    if (lang === void 0) {
      lang = 'en';
    }
    var dayIndex = date.getUTCDay();
    if (lang === 'ar') {
      return dayNames.ar[dayIndex];
    }
    return dayNames.en[dayIndex];
  };
  var getNextMonthName = function getNextMonthName(lang) {
    if (lang === void 0) {
      lang = 'en';
    }
    var nextMonth = (date.getUTCMonth() + 1) % 12;
    var nextYear = date.getUTCMonth() === 11 ? date.getUTCFullYear() + 1 : date.getUTCFullYear();
    return lang === 'ar' ? monthNames.ar[nextMonth] : monthNames.en[nextMonth];
  };
  var getNextNDays = function getNextNDays(daysCount, availableDays) {
    if (availableDays === void 0) {
      availableDays = [];
    }
    var daysArray = [];
    var availableMap = new Map(availableDays.map(function (dayObj) {
      var _a = __read(Object.keys(dayObj), 1),
        dateStr = _a[0];
      return [dateStr, dayObj[dateStr]];
    }));
    var showNextMonth = false;
    var lastAvailableDayNextMonth = null;
    for (var i = 0; i < daysCount; i++) {
      var nextDay = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + i)); // Create a new Date object based on the initial UTC timestamp
      var dayString = nextDay.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
      var dayOfMonth = nextDay.getUTCDate();
      var monthNameEn = monthNames.en[nextDay.getUTCMonth()];
      var monthNameAr = monthNames.ar[nextDay.getUTCMonth()];
      var dayNameEn = dayNames.en[nextDay.getUTCDay()];
      var dayNameAr = dayNames.ar[nextDay.getUTCDay()];
      // Check if the day falls in the next month
      if (nextDay.getUTCMonth() !== date.getUTCMonth()) {
        showNextMonth = true;
        if (availableMap.has(dayString)) {
          lastAvailableDayNextMonth = dayOfMonth;
        }
      }
      daysArray.push({
        day: dayString,
        dayOfMonth: dayOfMonth,
        // Day of the month
        monthNameEn: monthNameEn,
        // Month name in English
        monthNameAr: monthNameAr,
        // Month name in Arabic
        dayNameEn: dayNameEn,
        // Day name in English
        dayNameAr: dayNameAr,
        // Day name in Arabic
        available: availableMap.has(dayString),
        // Mark as available if date exists in availableMap
        timestamp: availableMap.get(dayString) || null // Add timestamp if available
      });
    }
    return {
      days: daysArray,
      showNextMonth: showNextMonth,
      lastAvailableDayNextMonth: lastAvailableDayNextMonth
    };
  };
  return {
    day: getDay,
    month: getMonth,
    year: getYear,
    hours: getHours,
    minutes: getMinutes,
    seconds: getSeconds,
    time: getTime,
    monthName: getMonthName,
    dayName: getDayName,
    nextMonthName: getNextMonthName,
    nextNDays: getNextNDays,
    timeForView: getTimeForView
  };
  // Example usage:
  // const timestampFunctions = createTimestampAndNextNDays(1724187600);
  // console.log(`Day: ${timestampFunctions.day()}`);
  // console.log(`Month: ${timestampFunctions.month()}`);
  // console.log(`Month Name (EN): ${timestampFunctions.monthName('en')}`);
  // console.log(`Month Name (AR): ${timestampFunctions.monthName('ar')}`);
  // console.log(`Day Name (EN): ${timestampFunctions.dayName('en')}`);
  // console.log(`Day Name (AR): ${timestampFunctions.dayName('ar')}`);
  // console.log(`Year: ${timestampFunctions.year()}`);
  // console.log(`Time: ${timestampFunctions.time()}`);
  // Pass an array of date-to-timestamp mappings
  // const availableDays = [
  //   { "2024-08-21": 1724187600 },
  //   { "2024-08-22": 1724274000 },
  //   { "2024-08-24": 1724446800 },
  //   { "2024-08-25": 1724533200 },
  //   { "2024-08-26": 1724619600 },
  //   { "2024-08-27": 1724706000 },
  //   { "2024-08-29": 1724878800 },
  //   { "2024-08-31": 1725051600 },
  //   { "2024-09-01": 1725138000 },
  //   { "2024-09-02": 1725224400 },
  //   { "2024-09-03": 1725310800 },
  //   { "2024-09-04": 1725397200 },
  //   { "2024-09-05": 1725483600 },
  //   { "2024-09-07": 1725656400 },
  //   { "2024-09-08": 1725742800 },
  //   { "2024-09-09": 1725829200 },
  //   { "2024-09-10": 1725915600 },
  //   { "2024-09-11": 1726002000 },
  //   { "2024-09-12": 1726088400 },
  //   { "2024-09-14": 1726261200 },
  //   { "2024-09-15": 1726347600 },
  //   { "2024-09-16": 1726434000 },
  //   { "2024-09-17": 1726520400 },
  //   { "2024-09-18": 1726606800 },
  //   { "2024-09-19": 1726693200 }
  // ];
  // console.log(`Next 10 Days with specific available days: ${JSON.stringify(timestampFunctions.nextNDays(10, availableDays).days, null, 2)}`);
};
export function alertSafe(message) {
  if (__IS_CSR__) {
    alert(message);
  }
}